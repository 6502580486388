import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment-timezone";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import "daterangepicker";
import Pagination from "react-js-pagination";
import Form from "react-bootstrap/Form";
import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  Table,
  TableRow,
  TableCell,
  WidthType,
  BorderStyle,
  SymbolRun,
  TabStopType,
  Tab,
  AlignmentType,
} from "docx";
import { saveAs } from "file-saver";

const Admins = () => {
  const [dataForm, setDataForm] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectAll, setSelectAll] = useState(false);
  const [hasNgayVaoLam, setHasNgayVaoLam] = useState("1"); // Mặc định là "Xem tất cả"
  const itemsPerPage = 60;
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  let isFirst = false;

  const checkPassword = () => {
    const password = prompt("Vui lòng nhập mật khẩu:");

    if (password === "10") {
      alert("Đăng nhập thành công!");
      setIsAuthenticated(true); // Đánh dấu người dùng đã xác thực
      return;
    } else {
      alert("Mật khẩu sai, vui lòng thử lại.");
      window.location.reload(); // Tải lại trang để yêu cầu nhập lại
    }
  };

  useEffect(() => {
    if (isFirst) return;
    checkPassword(); // Kiểm tra mật khẩu ngay khi trang được tải
    isFirst = true;
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchData(); // Chỉ gọi fetchData nếu người dùng đã nhập đúng mật khẩu
    }
  }, [isAuthenticated, startDate, endDate, currentPage, hasNgayVaoLam]);

  const fetchData = async () => {
    try {
      let filter = {};

      if (hasNgayVaoLam === "2") {
        filter.hasNgayVaoLam = "true"; // Có ngày vào làm
      } else if (hasNgayVaoLam === "3") {
        filter.hasNgayVaoLam = "false"; // Không có ngày vào làm
      }

      const response = await axios.get(
        "https://servertd.congtien.com.vn/api/admin/users",
        // "http://localhost:30002/api/admin/users",
        {
          params: {
            startDate: startDate,
            endDate: endDate,
            page: currentPage,
            limit: itemsPerPage,
            hasNgayVaoLam: filter.hasNgayVaoLam || null,
          },
        }
      );
      const updatedData = response.data.users.map((user) => ({
        ...user,
        checked: selectAll,
      }));
      setDataForm(updatedData);
      console.log(updatedData);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Lỗi khi lấy dữ liệu:", error);
    }
  };

  const handleExport = async () => {
    const selectedItems = dataForm
      .filter((item) => item.checked)
      .map((item) => item._id);
    console.log("Selected Items: ", selectedItems);

    if (selectedItems.length === 0) {
      alert("Chọn Ứng viên để xuất dữ liệu!");
      return;
    }

    try {
      setIsOverlayVisible(true);
      await axios.post("https://servertd.congtien.com.vn/export", {
        // await axios.post("http://localhost:30002/export", {
        ids: selectedItems,
      });
      alert("Dữ liệu được xuất thành công!");
    } catch (error) {
      console.error("Error exporting data:", error);
      alert("Lỗi khi xuất dữ liệu");
    } finally {
      setIsOverlayVisible(false);
    }
  };

  const handleCallback = (start, end) => {
    setStartDate(start.format("YYYY-MM-DD"));
    setEndDate(end.format("YYYY-MM-DD"));
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSelectChange = (event) => {
    setHasNgayVaoLam(event.target.value);
  };

  const handleCheckboxChange = (id) => {
    setDataForm((prev) =>
      prev.map((item) =>
        item._id === id ? { ...item, checked: !item.checked } : item
      )
    );
  };

  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
    setDataForm((prev) =>
      prev.map((item) => ({ ...item, checked: !selectAll }))
    );
  };
  const createWordDocument = (userData) => {
    function formatDocumentTTBT(documentArray) {
      if (!Array.isArray(documentArray) || documentArray.length === 0) {
        return "";
      }

      const lines = documentArray
        .map(
          (item, index) =>
            `${index + 1}.      ${item.thoigian} - ${item.congviec}`
        )
        .join("\n");

      let s = [];
      for (let i = 0; i < lines.split("\n").length; i++) {
        if (i === 0) {
          s.push(new TextRun({ text: lines.split("\n")[i], size: 24 }));
        } else {
          s.push(
            new TextRun({ text: lines.split("\n")[i], break: 1, size: 24 })
          );
        }
      }
      const paragraph = new Paragraph({
        children: s,
        spacing: {
          line: 276, // Line spacing 1.15
        },
      });

      return paragraph;
    }
    // Hàm tạo tiêu đề với nội dung
    function createHeader() {
      return new Table({
        rows: [
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "Mẫu số: 01/NS-TD",
                        size: 24,
                      }),
                    ],
                    alignment: AlignmentType.LEFT,
                  }),
                ],
              }),
            ],
          }),
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "Lần ban hành/ sửa đổi: 1/0",
                        size: 24,
                      }),
                    ],
                    alignment: AlignmentType.LEFT,
                  }),
                ],
              }),
            ],
          }),
        ],
        width: {
          size: 2800, // Tổng chiều rộng của bảng
          type: WidthType.DXA,
        },
        borders: {
          top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
          bottom: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
          left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
          right: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
          insideHorizontal: {
            style: BorderStyle.NONE,
            size: 0,
            color: "FFFFFF",
          },
        },
        alignment: AlignmentType.RIGHT, // Căn chỉnh bảng ở giữa
      });
    }

    const doc = new Document({
      sections: [
        {
          properties: {
            page: {
              margin: {
                top: 700,
                right: 700,
                bottom: 700,
                left: 700,
              },
            },
          },

          children: [
            createHeader(), // Thêm tiêu đề vào tài liệu
            // Title
            new Paragraph({
              children: [
                new TextRun({
                  text: "CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM",
                  bold: true,
                  allCaps: true,
                  size: 24,
                }),
              ],
              alignment: AlignmentType.CENTER,
              spacing: {
                line: 276, // Line spacing 1.5
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Độc lập - Tự do - Hạnh phúc",
                  bold: true,
                  size: 24,
                }),
              ],
              alignment: AlignmentType.CENTER,
              spacing: {
                line: 276, // Line spacing 1.5
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Gò Công, Ngày ${new Date().getDate()} tháng ${
                    new Date().getMonth() + 1
                  } năm ${new Date().getFullYear()}`,
                  italics: true,
                  size: 24,
                }),
              ],
              alignment: AlignmentType.RIGHT,
              spacing: {
                line: 276, // Line spacing 1.5
              },
            }),
            new Paragraph({
              children: [],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "PHIẾU ĐĂNG KÝ DỰ TUYỂN LAO ĐỘNG",
                  bold: true,
                  allCaps: true,
                  size: 32,
                }),
              ],
              alignment: AlignmentType.CENTER,
              spacing: {
                line: 276, // Line spacing 1.5
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Kính gửi: CÔNG TY CỔ PHẦN MAY CÔNG TIẾN",
                  bold: true,
                  size: 24,
                }),
              ],
              alignment: AlignmentType.CENTER,
              spacing: {
                line: 276, // Line spacing 1.5
              },
            }),
            new Paragraph({
              children: [],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Họ và tên: ${userData.fullname || ""}`,
                  allCaps: true,
                  size: 24,
                }),
              ],
              spacing: {
                line: 276, // Line spacing 1.5
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Ngày sinh: ${userData.date || ""}`,
                  size: 24,
                }),
              ],
              spacing: {
                line: 276, // Line spacing 1.5
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Nơi sinh: ${userData.noisinh || ""}`,
                  size: 24,
                }),
              ],
              spacing: {
                line: 276, // Line spacing 1.5
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Số CMND/CCCD: ${userData.cccd || ""}`,
                  size: 24,
                }),
                new TextRun({
                  text: "               ",
                  size: 24,
                }),
                new TextRun({
                  text: `Ngày cấp: ${userData.ngaycapcccd || ""}`,
                  size: 24,
                }),
              ],
              spacing: {
                line: 276, // Line spacing 1.5
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Nơi cấp: ${userData.noicapcccd || ""}`,
                  size: 24,
                }),
              ],
              spacing: {
                line: 276, // Line spacing 1.5
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  children: [
                    `TĐ Văn Hóa: ${userData.tdvh || ""}/12`,
                    "                          ",
                    `Chuyên môn: ${userData.chuyenmon || ""}`,
                  ],
                  size: 24,
                }),
              ],
              tabStops: [
                {
                  type: TabStopType.RIGHT,
                  position: 5000,
                },
              ],
              spacing: {
                line: 276, // Line spacing 1.5
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Ngoại ngữ: ${userData.ngoaingu || ""}`,
                  size: 24,
                }),
                new TextRun({
                  text: "                ",
                  size: 24,
                }),
                new TextRun({
                  text: `Chuyên ngành: ${userData.chuyennganh || ""}`,
                  size: 24,
                }),
              ],
              spacing: {
                line: 276, // Line spacing 1.5
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Số điện thoại: ${userData.numberPhone || ""}`,
                  size: 24,
                }),
              ],
              spacing: {
                line: 276, // Line spacing 1.5
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Email: ${userData.email || "Không có"}`,
                  size: 24,
                }),
              ],
              spacing: {
                line: 276, // Line spacing 1.5
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Thường trú: ${userData.sonha || ""}, ${
                    userData.xa || ""
                  }, ${userData.huyen || ""}, ${userData.tinh || ""}`,
                  size: 24,
                }),
              ],
              spacing: {
                line: 276, // Line spacing 1.5
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Tạm trú: ${
                    [
                      userData.sonhatamtru,
                      userData.tamtruXa,
                      userData.tamtruHuyen,
                      userData.tamtruTinh,
                    ]
                      .filter(Boolean)
                      .join(", ") || ""
                  }`,
                  size: 24,
                }),
              ],
              spacing: {
                line: 276, // Line spacing 1.5
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Họ tên chủ hộ: ${userData.hotench || ""}`,
                  size: 24,
                }),
                new TextRun({
                  text: `     `,
                  size: 24,
                }),
                new TextRun({
                  text: `Năm sinh chủ hộ: ${userData.ngaysinhch || ""}`,
                  size: 24,
                }),
                new TextRun({
                  text: `     `,
                  size: 24,
                }),
                new TextRun({
                  text: `Mối quan hệ với chủ hộ: ${userData.moiquanhech || ""}`,
                  size: 24,
                }),
              ],
              spacing: {
                line: 276, // Line spacing 1.5
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Thẻ ATM Đông Á Bank: ${userData.stk || ""}`,
                  size: 24,
                }),
              ],
              spacing: {
                line: 276, // Line spacing 1.5
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Thẻ ATM Vietcombank: ${userData.stkVCB || ""}`,
                  size: 24,
                }),
              ],
              spacing: {
                line: 276, // Line spacing 1.5
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "(Khi có thay đổi thông tin cá nhân, vui lòng liên hệ Phòng Nhân Sự để cập nhật lại)",
                  size: 24,
                  bold: true,
                }),
              ],
              alignment: AlignmentType.CENTER,
              spacing: {
                line: 276, // Line spacing 1.5
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Sau khi nghiên cứu nội dung thông báo tuyển lao động, tôi đăng ký dự tuyển vào vị trí ${
                    userData.vtut || "________________________________"
                  }`,
                  size: 24,
                }),
              ],
              spacing: {
                line: 276, // Line spacing 1.5
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Tôi xin cam đoan lời khai trên là đúng sự thật và thực hiện đúng các quy định về tuyển dụng lao động.",
                  size: 24,
                }),
              ],
              spacing: {
                line: 276, // Line spacing 1.5
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Các thông tin cá nhân trên để Công ty liên lạc với tôi khi cần thiết. Nếu có thay đổi thông tin cá nhân (kể cả số điện thoại), tôi sẽ báo lại với Bộ phận Nhân sự của Đơn vị để cập nhật, thay đổi./.",
                  size: 24,
                }),
              ],
              spacing: {
                line: 276, // Line spacing 1.5
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  children: [new Tab(), "NGƯỜI ĐĂNG KÝ DỰ TUYỂN"],
                  // text: "NGƯỜI ĐĂNG KÝ DỰ TUYỂN",
                  bold: true,
                  size: 24,
                }),
              ],
              tabStops: [
                {
                  type: TabStopType.CENTER,
                  position: 8000,
                },
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  children: [new Tab(), "(Ký và ghi rõ họ tên)"],
                  // text: "NGƯỜI ĐĂNG KÝ DỰ TUYỂN",
                  italics: true,
                  size: 24,
                }),
              ],
              tabStops: [
                {
                  type: TabStopType.CENTER,
                  position: 8000,
                },
              ],
            }),
            new Paragraph({
              children: [],
            }),
            new Paragraph({
              children: [],
            }),
            new Paragraph({
              children: [],
            }),
            new Paragraph({
              children: [],
            }),
            new Paragraph({
              children: [],
            }),
            new Paragraph({
              children: [],
            }),
            new Paragraph({
              children: [],
            }),
            new Paragraph({
              children: [],
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "PHỎNG VẤN VIÊN",
                  bold: true,
                  allCaps: true,
                  size: 24,
                }),
              ],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Hồ sơ cá nhân kèm theo, gồm có: ",
                  size: 24,
                }),
              ],
            }),
            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new SymbolRun({
                              char: "F071",
                              bold: true,
                              size: 30,
                            }),
                            new TextRun({
                              text: " Đơn xin việc (theo mẫu công ty cung cấp)",
                              size: 24,
                            }),
                          ],
                        }),
                      ],
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new SymbolRun({
                              char: "F071",
                              bold: true,
                              size: 30,
                            }),
                            new TextRun({
                              text: " Bản sao Giấy xác nhận thông tin cư trú",
                              size: 24,
                            }),
                          ],
                        }),
                      ],
                    }),
                  ],
                }),
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new SymbolRun({
                              char: "F071",
                              bold: true,
                              size: 30,
                            }),
                            new TextRun({
                              text: " Bản sao giấy CCCD/ CMND",
                              size: 24,
                            }),
                          ],
                        }),
                      ],
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new SymbolRun({
                              char: "F071",
                              bold: true,
                              size: 30,
                            }),
                            new TextRun({
                              text: " Bản sao sổ BHXH kèm tờ rời đóng BHXH",
                              size: 24,
                            }),
                          ],
                        }),
                      ],
                    }),
                  ],
                }),
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new SymbolRun({
                              char: "F071",
                              bold: true,
                              size: 30,
                            }),
                            new TextRun({
                              text: " Bản sao Sơ yếu lý lịch",
                              size: 24,
                            }),
                          ],
                        }),
                      ],
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new SymbolRun({
                              char: "F071",
                              bold: true,
                              size: 30,
                            }),
                            new TextRun({
                              text: " Bản sao QĐ v/v hưởng trợ cấp BHXH 1 lần",
                              size: 24,
                            }),
                          ],
                        }),
                      ],
                    }),
                  ],
                }),
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new SymbolRun({
                              char: "F071",
                              bold: true,
                              size: 30,
                            }),
                            new TextRun({
                              text: "Bản sao Giấy xác nhận hạnh kiểm",
                              size: 24,
                            }),
                          ],
                        }),
                      ],
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new SymbolRun({
                              char: "F071",
                              bold: true,
                              size: 30,
                            }),
                            new TextRun({
                              text: "___________________________________",
                              size: 24,
                            }),
                          ],
                        }),
                      ],
                    }),
                  ],
                }),
              ],
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              borders: {
                top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                bottom: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                right: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                insideHorizontal: {
                  style: BorderStyle.NONE,
                  size: 0,
                  color: "FFFFFF",
                },
                insideVertical: {
                  style: BorderStyle.NONE,
                  size: 0,
                  color: "FFFFFF",
                },
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `*Công ty không yêu cầu người lao động cung cấp hồ sơ dự tuyển ban đầu. Phòng nhân sự cung cấp biểu mẫu và photo hồ sơ tuyển dụng sau khi đối chiếu thông tin trên bản chính, người lao động không tốn chi phí làm hồ sơ khi dự tuyển vào công ty. Công ty chỉ photo hồ sơ để lưu trữ (Giấy xác nhận hạnh kiểm, Sơ yếu lý lịch, Giấy xác nhận thông tin cư trú … ) nếu người lao động đã có sẵn hồ sơ trước đó.`,
                  italics: true,
                  size: 24,
                }),
              ],
            }),
            // Trang mới
            new Paragraph({
              children: [
                new TextRun({
                  text: "KHẢO SÁT THÔNG TIN TUYỂN DỤNG",
                  bold: true,
                  size: 32,
                }),
              ],
              pageBreakBefore: true,
              alignment: AlignmentType.CENTER,
              spacing: {
                line: 276, // Line spacing 1.5
              },
            }),
            // Section I
            new Paragraph({
              children: [
                new TextRun({
                  text: "I. Quá trình bản thân (tóm tắt từ lúc 18 tuổi đến nay làm gì? ở đâu?)",
                  bold: true,
                  size: 24,
                }),
              ],
              spacing: {
                before: 200,
                line: 276, // Line spacing 1.5
              },
            }),
            // Quá trình bản thân
            formatDocumentTTBT(userData.tomtacbt),
            // Section II
            new Paragraph({
              children: [
                new TextRun({
                  text: "II. Quan hệ gia đình:",
                  bold: true,
                  size: 24,
                }),
              ],
              spacing: {
                before: 300,
                line: 360, // Line spacing 1.5
              },
            }),
            // Table for family relationships
            new Table({
              rows: [
                new TableRow({
                  children: [
                    "Mối quan hệ",
                    "Họ tên",
                    "Ngày sinh",
                    "Nghề nghiệp",
                    "Chỗ ở hiện tại",
                    "Số điện thoại",
                  ].map(
                    (text) =>
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text,
                                bold: true,
                                size: 24,
                              }),
                            ],
                            alignment: AlignmentType.CENTER,
                            spacing: {
                              line: 276, // Line spacing 1.15
                            },
                          }),
                        ],
                        margins: {
                          // Adjust the margins inside each cell
                          top: 100,
                          bottom: 100,
                          left: 100,
                          right: 100,
                        },
                        width: {
                          size: 1750, // Adjust the width for each column if needed
                          type: WidthType.DXA,
                        },
                      })
                  ),
                }),

                ...[
                  [
                    `${userData.ong ? userData.ong : "Ông"}`,
                    `${userData.hotenong ? userData.hotenong : ""}`,
                    `${userData.ngaysinhong ? userData.ngaysinhong : ""}`,
                    `${userData.nghenghiepong ? userData.nghenghiepong : ""}`,
                    `${userData.chooong ? userData.chooong : ""}`,
                    `${userData.sdtong ? userData.sdtong : ""}`,
                  ],
                  [
                    `${userData.ba ? userData.ba : "Bà"}`,
                    `${userData.hotenba ? userData.hotenba : ""}`,
                    `${userData.ngaysinhba ? userData.ngaysinhba : ""}`,
                    `${userData.nghenghiepba ? userData.nghenghiepba : ""}`,
                    `${userData.chooba ? userData.chooba : ""}`,
                    `${userData.sdtba ? userData.sdtba : ""}`,
                  ],
                  [
                    `${userData.cha ? userData.cha : "Cha"}`,
                    `${userData.hotencha ? userData.hotencha : ""}`,
                    `${userData.ngaysinhcha ? userData.ngaysinhcha : ""}`,
                    `${userData.nghenghiepcha ? userData.nghenghiepcha : ""}`,
                    `${userData.choocha ? userData.choocha : ""}`,
                    `${userData.sdtcha ? userData.sdtcha : ""}`,
                  ],
                  [
                    `${userData.me ? userData.me : "Mẹ"}`,
                    `${userData.hotenme ? userData.hotenme : ""}`,
                    `${userData.ngaysinhme ? userData.ngaysinhme : ""}`,
                    `${userData.nghenghiepme ? userData.nghenghiepme : ""}`,
                    `${userData.choome ? userData.choome : ""}`,
                    `${userData.sdtme ? userData.sdtme : ""}`,
                  ],
                  [
                    `${userData.chavo ? userData.chavo : "Cha Vợ/Chồng"}`,
                    `${userData.hotenchavo ? userData.hotenchavo : ""}`,
                    `${userData.ngaysinhchavo ? userData.ngaysinhchavo : ""}`,
                    `${
                      userData.nghenghiepchavo ? userData.nghenghiepchavo : ""
                    }`,
                    `${userData.choochavo ? userData.choochavo : ""}`,
                    `${userData.sdtchavo ? userData.sdtchavo : ""}`,
                  ],
                  [
                    `${userData.mevo ? userData.mevo : "Mẹ Vợ/Chồng"}`,
                    `${userData.hotenmevo ? userData.hotenmevo : ""}`,
                    `${userData.ngaysinhmevo ? userData.ngaysinhmevo : ""}`,
                    `${userData.nghenghiepmevo ? userData.nghenghiepmevo : ""}`,
                    `${userData.choomevo ? userData.choome : ""}`,
                    `${userData.sdtmevo ? userData.sdtmevo : ""}`,
                  ],
                  [
                    `${userData.chongvo ? userData.chongvo : "Vợ/Chồng"}`,
                    `${userData.hotenchongvo ? userData.hotenchongvo : ""}`,
                    `${
                      userData.ngaysinhchongvo ? userData.ngaysinhchongvo : ""
                    }`,
                    `${
                      userData.nghenghiepchongvo
                        ? userData.nghenghiepchongvo
                        : ""
                    }`,
                    `${userData.choochongvo ? userData.choochongvo : ""}`,
                    `${userData.sdtchongvo ? userData.sdtchongvo : ""}`,
                  ],
                  [
                    `${userData.con1 ? userData.con1 : "Con 1"}`,
                    `${userData.hotencon1 ? userData.hotencon1 : ""}`,
                    `${userData.ngaysinhcon1 ? userData.ngaysinhcon1 : ""}`,
                    `${userData.nghenghiepcon1 ? userData.nghenghiepcon1 : ""}`,
                    `${userData.choocon1 ? userData.choocon1 : ""}`,
                    `${userData.sdtcon1 ? userData.sdtcon1 : ""}`,
                  ],
                  [
                    `${userData.con2 ? userData.con2 : "Con 2"}`,
                    `${userData.hotencon2 ? userData.hotencon2 : ""}`,
                    `${userData.ngaysinhcon2 ? userData.ngaysinhcon2 : ""}`,
                    `${userData.nghenghiepcon2 ? userData.nghenghiepcon2 : ""}`,
                    `${userData.choocon2 ? userData.choocon2 : ""}`,
                    `${userData.sdtcon2 ? userData.sdtcon2 : ""}`,
                  ],
                  [
                    `${userData.con3 ? userData.con3 : "Con 3"}`,
                    `${userData.hotencon3 ? userData.hotencon3 : ""}`,
                    `${userData.ngaysinhcon3 ? userData.ngaysinhcon3 : ""}`,
                    `${userData.nghenghiepcon3 ? userData.nghenghiepcon3 : ""}`,
                    `${userData.choocon3 ? userData.choocon3 : ""}`,
                    `${userData.sdtcon3 ? userData.sdtcon3 : ""}`,
                  ],
                  [
                    `${userData.a1 ? userData.a1 : "Anh 1"}`,
                    `${userData.hotena1 ? userData.hotena1 : ""}`,
                    `${userData.ngaysinha1 ? userData.ngaysinha1 : ""}`,
                    `${userData.nghenghiepa1 ? userData.nghenghiepa1 : ""}`,
                    `${userData.chooa1 ? userData.chooa1 : ""}`,
                    `${userData.sdta1 ? userData.sdta1 : ""}`,
                  ],
                  [
                    `${userData.a2 ? userData.a2 : "Anh 2"}`,
                    `${userData.hotena2 ? userData.hotena2 : ""}`,
                    `${userData.ngaysinha2 ? userData.ngaysinha2 : ""}`,
                    `${userData.nghenghiepa2 ? userData.nghenghiepa2 : ""}`,
                    `${userData.chooa2 ? userData.chooa2 : ""}`,
                    `${userData.sdta2 ? userData.sdta2 : ""}`,
                  ],
                  [
                    `${userData.c1 ? userData.c1 : "Chị 1"}`,
                    `${userData.hotenc1 ? userData.hotenc1 : ""}`,
                    `${userData.ngaysinhc1 ? userData.ngaysinhc1 : ""}`,
                    `${userData.nghenghiepc1 ? userData.nghenghiepc1 : ""}`,
                    `${userData.chooc1 ? userData.chooc1 : ""}`,
                    `${userData.sdtc1 ? userData.sdtc1 : ""}`,
                  ],
                  [
                    `${userData.c2 ? userData.c2 : "Chị 2"}`,
                    `${userData.hotenc2 ? userData.hotenc2 : ""}`,
                    `${userData.ngaysinhc2 ? userData.ngaysinhc2 : ""}`,
                    `${userData.nghenghiepc2 ? userData.nghenghiepc2 : ""}`,
                    `${userData.chooc2 ? userData.chooc2 : ""}`,
                    `${userData.sdtc2 ? userData.sdtc2 : ""}`,
                  ],
                  [
                    `${userData.e1 ? userData.e1 : "Em 1"}`,
                    `${userData.hotene1 ? userData.hotene1 : ""}`,
                    `${userData.ngaysinhe1 ? userData.ngaysinhe1 : ""}`,
                    `${userData.nghenghiepe1 ? userData.nghenghiepe1 : ""}`,
                    `${userData.chooe1 ? userData.chooe1 : ""}`,
                    `${userData.sdte1 ? userData.sdte1 : ""}`,
                  ],
                  [
                    `${userData.e2 ? userData.e2 : "Em 2"}`,
                    `${userData.hotene2 ? userData.hotene2 : ""}`,
                    `${userData.ngaysinhe2 ? userData.ngaysinhe2 : ""}`,
                    `${userData.nghenghiepe2 ? userData.nghenghiepe2 : ""}`,
                    `${userData.chooe2 ? userData.chooe2 : ""}`,
                    `${userData.sdte2 ? userData.sdte2 : ""}`,
                  ],
                  [
                    `${
                      userData.acevo ? userData.acevo : "Anh/Chị/Em Chồng/Vợ"
                    }`,
                    `${userData.hotenacevo ? userData.hotenacevo : ""}`,
                    `${userData.ngaysinhacevo ? userData.ngaysinhacevo : ""}`,
                    `${
                      userData.nghenghiepacevo ? userData.nghenghiepacevo : ""
                    }`,
                    `${userData.chooacevo ? userData.chooacevo : ""}`,
                    `${userData.sdtacevo ? userData.sdtacevo : ""}`,
                  ],
                  [
                    `${userData.khac ? userData.khac : "Khác"}`,
                    `${userData.hotenkhac ? userData.hotenkhac : ""}`,
                    `${userData.ngaysinhkhac ? userData.ngaysinhkhac : ""}`,
                    `${userData.nghenghiepkhac ? userData.nghenghiepkhac : ""}`,
                    `${userData.chookhac ? userData.chookhac : ""}`,
                    `${userData.sdtkhac ? userData.sdtkhac : ""}`,
                  ],
                ].map(
                  (row) =>
                    new TableRow({
                      children: row.map(
                        (text) =>
                          new TableCell({
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text,
                                    size: 18,
                                  }),
                                ],
                                spacing: {
                                  line: 276, // Line spacing 1.5
                                },
                              }),
                            ],
                            margins: {
                              // Adjust the margins inside each cell
                              top: 100,
                              bottom: 100,
                              left: 100,
                              right: 100,
                            },
                            width: {
                              size: 1750, // Adjust the width for each column if needed
                              type: WidthType.DXA,
                            },
                          })
                      ),
                    })
                ),
              ],
              width: {
                size: 10500, // Total width of the table
                type: WidthType.DXA,
              },
              margins: {
                // Optional: Set table margins if needed
                top: 100,
                bottom: 100,
                left: 100,
                right: 100,
              },
            }),
          ],
        },
      ],
    });

    return doc;
  };

  const handleExportWord = async (userData) => {
    const doc = createWordDocument(userData);
    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `phieu_dang_ky_${userData.fullname}.docx`);
    });
  };

  return (
    <div className="App">
      {isOverlayVisible && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      <div className="container bg-light mt-4">
        <h1 className="text-center pt-4 text-primary">DANH SÁCH ỨNG VIÊN</h1>
        <div className="row mt-4">
          <div className="col-4">
            <div className="mb-4">
              <DateRangePicker
                initialSettings={{
                  showDropdowns: true,
                  minYear: 1901,
                  autoApply: true,
                  locale: {
                    format: "DD/MM/YYYY",
                  },
                }}
                onCallback={handleCallback}
              >
                <input type="text" className="form-control col-4" />
              </DateRangePicker>
            </div>
          </div>
          <div className="col-4">
            <Form.Select
              aria-label="Default select example"
              onChange={handleSelectChange}
              value={hasNgayVaoLam}
            >
              <option value="1">Xem tất cả</option>
              <option value="2">Có ngày vào làm</option>
              <option value="3">Không có ngày vào làm</option>
            </Form.Select>
          </div>
          <div className="col-4">
            <button
              onClick={handleExport}
              className="btn btn-primary w-100 height-fill-available mb-4 font-size-1-2"
            >
              <i className="fa-solid fa-print mr-3"></i>
              <span>Xuất ra Google Sheet</span>
            </button>
          </div>
        </div>

        <h5 className="text-danger">Bấm vào CMND/CCCD để xem hình ảnh</h5>
        <div className="row">
          <div className="col-12">
            <table className="table table-striped table-bordered table-cmnd-hover responsiveTable">
              <thead className="sticky-top">
                <tr>
                  <th>STT</th>
                  <th>
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                    />
                  </th>
                  <th>Ngày đăng ký</th>
                  <th>Ngày vào làm</th>
                  <th>Họ tên</th>
                  <th>Điện thoại</th>
                  <th>CMND/CCCD Mặt trước / sau</th>
                  {/* <th>Mặt trước / sau</th> */}
                  <th>Vị trí ứng tuyển</th>
                  <th>Word</th>
                </tr>
              </thead>
              <tbody>
                {dataForm.map((item, index) => (
                  <tr key={item._id}>
                    <td data-label="STT">
                      {index + 1 + (currentPage - 1) * itemsPerPage}
                    </td>
                    <td data-label="Chọn">
                      <input
                        type="checkbox"
                        checked={item.checked}
                        onChange={() => handleCheckboxChange(item._id)}
                      />
                    </td>
                    <td data-label="Ngày đăng ký">
                      {moment
                        .tz(item.createdAt, "UTC")
                        .tz("Asia/Bangkok")
                        .format("YYYY-MM-DD")}
                    </td>
                    <td data-label="Ngày vào làm">{`${
                      item.ngayvaolam || "Chưa có"
                    }`}</td>
                    {/* <td data-label="Ngày vào làm">{`ko có ngày vào làm`}</td> */}

                    <td data-label="Họ tên">{item.fullname}</td>
                    <td data-label="Điện thoại">{item.numberPhone}</td>
                    <td data-label="CMND/CCCD" className="cmnd-hover">
                      {item.cccd}
                      {/* <div>
                        <div className="row g-0">
                          <div className="col-6">
                            <img
                              src={item.imageUrl1}
                              className="img-fluid"
                              alt={item.fullname}
                            />
                          </div>
                          <div className="col-6">
                            <img
                              src={item.imageUrl2}
                              className="img-fluid"
                              alt={item.fullname}
                            />
                          </div>
                        </div>
                      </div> */}{" "}
                      Mặt{" "}
                      <a target="_blank" href={item.imageUrl1}>
                        trước
                      </a>{" "}
                      /{" "}
                      <a target="_blank" href={item.imageUrl2}>
                        sau
                      </a>
                    </td>
                    <td data-label="Vị trí ứng tuyển">{item.vtut}</td>
                    <td>
                      <button
                        className="btn btn-primary"
                        onClick={() => handleExportWord(item)}
                      >
                        Word
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={itemsPerPage}
              totalItemsCount={totalPages * itemsPerPage}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              itemClass="page-item"
              linkClass="page-link"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admins;