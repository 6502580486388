import React from "react";
import "./App.css";
// import Admins from "./admins/admins";
import AdminTests from "./admins/Admintest";
import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  return (
    <div className="App">
      {/* <Admins /> */}
      <AdminTests />
    </div>
  );
};

export default App;
